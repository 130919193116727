import React, { useState, useEffect } from "react";
import { Input, message, Upload, Checkbox, Modal } from "antd";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Toast, Image, Mask } from "antd-mobile";
import "./index.css";
import Http from "../../../utils/Http";
import { Spin } from "antd/es";
import { store } from "../../../store";
const AddBank = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const show = () => {
    setVisible(true);
  };
  const close = () => {
    setPhone("");
    setVisible(false);
  };
  // 添加银行卡
  const handleCheck = async () => {
    if (!phone) {
      return message.warning("请输入银行卡号");
    }
    try {
      const res = await Http.postForm(
        "/data/api.auth.transfer/userBankCardAccount",
        {
          bank_card_account: phone,
        }
      );
      if (res.code === 1) {
        Toast.show("操作成功");
        close();
        if (props.onVerify) {
          props.onVerify(); // 调用父组件传递的方法
          postGongmao();
        }
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  // 工猫信息
  const postGongmao = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.transfer/gongmao");
      if (res?.code === 1) {
        if (res?.data.url) {
          Toast.show("需先进行认证");
          setTimeout(() => {
            window.location.href = res?.data.url;
          }, 500);
        } else {
          Toast.show(res?.data.message);
        }
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });
  return (
    <Mask visible={visible} onMaskClick={() => setVisible(false)}>
      <div className={"buildMask4"}>
        <div
          style={{
            display: "flex",
            flex: 3,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              fontSize: 18,
              color: "#050303",
              fontWeight: 600,
              marginTop: 10,
              width: "100%",
            }}
          >
            添加银行卡
            <div>
              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="银行卡账户"
                style={{
                  fontSize: 14,
                  height: 48,
                  marginTop: 20,
                  borderRadius: 8,
                  width: "100%",
                  boxShadow: "none",
                  borderColor: "#d9d9d9",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                // flex: 1,
                height: "70px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: -9,
                position: "relative",
              }}
            >
              <Button
                style={{ height: 50, "--border-width": 0, borderRadius: 8 }}
                block
                onClick={() => {
                  close();
                }}
              >
                <div style={{ color: "#979797", fontSize: 16 }}>取消</div>
              </Button>
              <Button
                style={{ height: 50, "--border-width": 0, borderRadius: 8 }}
                block
                onClick={() => {
                  handleCheck();
                }}
              >
                {loading ? (
                  <Spin />
                ) : (
                  <div style={{ color: "#eb8322", fontSize: 16 }}>确定</div>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Mask>
  );
});

export default AddBank;
