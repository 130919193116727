import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: undefined,
  userinfo: undefined,
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserinfo: (state, action) => {
      state.userinfo = action.payload;
    },
  },
});

export const { setToken, setUserinfo } = appSlice.actions;

export default appSlice.reducer;
