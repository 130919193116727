import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { Button, Image } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import Http from "../../../utils/Http";
import "./index.less";
import { useSelector } from "react-redux";

import { setToken, setUserinfo } from "../../../store/app";
import { store } from "../../../store";

const GetCode = () => {
  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setkey] = useState([]);
  const token = useSelector((state) => state.App.token);
  const [image, setImage] = useState([]);
  const navigate = useNavigate();

  const getCode = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await Http.postForm("/api/v1/auth/captchaImage");
      if (res?.status === 10000) {
        setkey(res.data.IdKey);
        setImage(res.data.img);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  const handleGetCode = async () => {
    if (loading) {
      return;
    }
    try {
      const res = await Http.postForm("/data/api.login/in", {
        phone: tel,
        password: code,
      });
      if (res.code === 1) {
        store.dispatch(setToken(res.data.token.token));
        store.dispatch(setUserinfo(res.data));
        message.success("登录成功");
        navigate("/withdraw");
        // navigate("/mobile", { state: { tel: tel, code: code } });
      } else {
        message.warning(res.info);
      }
    } catch (e) {
      message.warning(e.info);
    } finally {
      setLoading(false);
      // getCode();
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/withdraw");
      // navigate("/charge");
      return;
    }
    // getCode();
  }, [token]);

  return (
    <div className="login-page">
      <div className="login-text">账号登录</div>
      <Input
        placeholder="请输入手机号码"
        type="number"
        maxLength={11}
        style={{
          fontSize: 14,
          height: 48,
          borderRadius: 8,
          boxShadow: "none",
          borderColor: "#d9d9d9",
        }}
        value={tel}
        onChange={(e) => {
          setTel(e.target.value);
        }}
      />
      <div className="code">
        <Input
          placeholder="请输入密码"
          type="password"
          // maxLength={4}
          style={{
            fontSize: 14,
            height: 48,
            borderRadius: 8,
            marginRight: 8,
            boxShadow: "none",
            borderColor: "#d9d9d9",
          }}
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        {/* <Image
          onClick={getCode}
          src={image}
          style={{ borderRadius: 8 }}
          width={112}
          height={48}
        /> */}
      </div>
      <Button
        disabled={tel.length !== 0 && code.length !== 0 ? false : true}
        style={{
          height: 48,
          borderRadius: 8,
          backgroundColor:
            tel.length !== 0 && code.length !== 0 ? "#FF850E" : "#B0B0B0",
          marginTop: 22,
        }}
        block
        onClick={() => {
          handleGetCode();
        }}
      >
        <div
          style={{
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          登录
        </div>
      </Button>
      {/* <div className="bottom">
        登录代表您已同意
        <span style={{ fontSize: 13, color: "#F03550" }}>
          《圈圈开黑用户协议》
        </span>
      </div> */}
    </div>
  );
};

export default GetCode;
